import { default as energy_45green_45practicesmXSZ62rHVdMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyx67JqIeskLMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderctMKwGQE4gMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexKoK5kAtkeKMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamYIBJlKOiD0Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderaCzjhx357hMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesZwXCW4LuhuMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_936fEY08yrgMMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexYDngEHSgWsMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexBDMBzBvQctMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93plk2SQ57EeMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexGgKBSsFiw1Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as ask_45a_45questionWm4zrKriFSMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunities4vdKcRDGHRMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faq0UwaPBgTJnMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesvRyNuH9IVEMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexlJ3kfp2OWFMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locations3wtux0iTq9Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersYVv9Km8txsMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as index9TsDAt54rDMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexono7WFMLAgMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexKXpiZY2mUSMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indextEPgfFEmMlMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as index6IzbWscp0JMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexs26NgHf5YwMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexd9lF45WnNtMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationKrEU37Y7GEMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionwRlGjGt2ajMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45place96nb3CoxPGMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenance3FGUsqOER1Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93OG5lu7dsvzMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexNk3YT0BlUzMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexnJNUvFEiswMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessElX879Kak8Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_933mBabYyLvBMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesYQNigqFNC5Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchsSd1lFM9QKMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhousel4AdtBGBiqMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionLuQ7GAmfhwMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageWvblOH3bk4Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexs8R8xKt98WMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsk3L6mlL8xqMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usuhQHg5rzNAMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsafNBtuDjAuMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxcLZCGCXAi4Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93a6UEbetD62Meta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stub4mUuDhc13EMeta } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub4mUuDhc13E } from "/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesmXSZ62rHVdMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesmXSZ62rHVdMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesmXSZ62rHVdMeta || {},
    alias: energy_45green_45practicesmXSZ62rHVdMeta?.alias || [],
    redirect: energy_45green_45practicesmXSZ62rHVdMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyx67JqIeskLMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyx67JqIeskLMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyx67JqIeskLMeta || {},
    alias: fun_45part_45journeyx67JqIeskLMeta?.alias || [],
    redirect: fun_45part_45journeyx67JqIeskLMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderctMKwGQE4gMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderctMKwGQE4gMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderctMKwGQE4gMeta || {},
    alias: homesafe_45colorado_45master_45builderctMKwGQE4gMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderctMKwGQE4gMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexKoK5kAtkeKMeta?.name ?? "about-us",
    path: indexKoK5kAtkeKMeta?.path ?? "/about-us",
    meta: indexKoK5kAtkeKMeta || {},
    alias: indexKoK5kAtkeKMeta?.alias || [],
    redirect: indexKoK5kAtkeKMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamYIBJlKOiD0Meta?.name ?? "about-us-our-team",
    path: our_45teamYIBJlKOiD0Meta?.path ?? "/about-us/our-team",
    meta: our_45teamYIBJlKOiD0Meta || {},
    alias: our_45teamYIBJlKOiD0Meta?.alias || [],
    redirect: our_45teamYIBJlKOiD0Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderaCzjhx357hMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderaCzjhx357hMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderaCzjhx357hMeta || {},
    alias: preferred_45lenderaCzjhx357hMeta?.alias || [],
    redirect: preferred_45lenderaCzjhx357hMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesZwXCW4LuhuMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesZwXCW4LuhuMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesZwXCW4LuhuMeta || {},
    alias: realtor_45resourcesZwXCW4LuhuMeta?.alias || [],
    redirect: realtor_45resourcesZwXCW4LuhuMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_936fEY08yrgMMeta?.name ?? "blog-post",
    path: _91post_936fEY08yrgMMeta?.path ?? "/blog/:post()",
    meta: _91post_936fEY08yrgMMeta || {},
    alias: _91post_936fEY08yrgMMeta?.alias || [],
    redirect: _91post_936fEY08yrgMMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexYDngEHSgWsMeta?.name ?? "blog-category-category",
    path: indexYDngEHSgWsMeta?.path ?? "/blog/category/:category()",
    meta: indexYDngEHSgWsMeta || {},
    alias: indexYDngEHSgWsMeta?.alias || [],
    redirect: indexYDngEHSgWsMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBDMBzBvQctMeta?.name ?? "blog",
    path: indexBDMBzBvQctMeta?.path ?? "/blog",
    meta: indexBDMBzBvQctMeta || {},
    alias: indexBDMBzBvQctMeta?.alias || [],
    redirect: indexBDMBzBvQctMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93plk2SQ57EeMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93plk2SQ57EeMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93plk2SQ57EeMeta || {},
    alias: _91_46_46_46slug_93plk2SQ57EeMeta?.alias || [],
    redirect: _91_46_46_46slug_93plk2SQ57EeMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexGgKBSsFiw1Meta?.name ?? "campaigns",
    path: indexGgKBSsFiw1Meta?.path ?? "/campaigns",
    meta: indexGgKBSsFiw1Meta || {},
    alias: indexGgKBSsFiw1Meta?.alias || [],
    redirect: indexGgKBSsFiw1Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionWm4zrKriFSMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionWm4zrKriFSMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionWm4zrKriFSMeta || {},
    alias: ask_45a_45questionWm4zrKriFSMeta?.alias || [],
    redirect: ask_45a_45questionWm4zrKriFSMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunities4vdKcRDGHRMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunities4vdKcRDGHRMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunities4vdKcRDGHRMeta || {},
    alias: career_45opportunities4vdKcRDGHRMeta?.alias || [],
    redirect: career_45opportunities4vdKcRDGHRMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faq0UwaPBgTJnMeta?.name ?? "contact-us-faq",
    path: faq0UwaPBgTJnMeta?.path ?? "/contact-us/faq",
    meta: faq0UwaPBgTJnMeta || {},
    alias: faq0UwaPBgTJnMeta?.alias || [],
    redirect: faq0UwaPBgTJnMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesvRyNuH9IVEMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesvRyNuH9IVEMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesvRyNuH9IVEMeta || {},
    alias: homeowner_45resourcesvRyNuH9IVEMeta?.alias || [],
    redirect: homeowner_45resourcesvRyNuH9IVEMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexlJ3kfp2OWFMeta?.name ?? "contact-us",
    path: indexlJ3kfp2OWFMeta?.path ?? "/contact-us",
    meta: indexlJ3kfp2OWFMeta || {},
    alias: indexlJ3kfp2OWFMeta?.alias || [],
    redirect: indexlJ3kfp2OWFMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locations3wtux0iTq9Meta?.name ?? "contact-us-our-locations",
    path: our_45locations3wtux0iTq9Meta?.path ?? "/contact-us/our-locations",
    meta: our_45locations3wtux0iTq9Meta || {},
    alias: our_45locations3wtux0iTq9Meta?.alias || [],
    redirect: our_45locations3wtux0iTq9Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersYVv9Km8txsMeta?.name ?? "current-offers",
    path: current_45offersYVv9Km8txsMeta?.path ?? "/current-offers",
    meta: current_45offersYVv9Km8txsMeta || {},
    alias: current_45offersYVv9Km8txsMeta?.alias || [],
    redirect: current_45offersYVv9Km8txsMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: index9TsDAt54rDMeta?.name ?? "events-event",
    path: index9TsDAt54rDMeta?.path ?? "/events/:event()",
    meta: index9TsDAt54rDMeta || {},
    alias: index9TsDAt54rDMeta?.alias || [],
    redirect: index9TsDAt54rDMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexono7WFMLAgMeta?.name ?? "events",
    path: indexono7WFMLAgMeta?.path ?? "/events",
    meta: indexono7WFMLAgMeta || {},
    alias: indexono7WFMLAgMeta?.alias || [],
    redirect: indexono7WFMLAgMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexKXpiZY2mUSMeta?.name ?? "homes-location-community",
    path: indexKXpiZY2mUSMeta?.path ?? "/homes/:location()/:community()",
    meta: indexKXpiZY2mUSMeta || {},
    alias: indexKXpiZY2mUSMeta?.alias || [],
    redirect: indexKXpiZY2mUSMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indextEPgfFEmMlMeta?.name ?? "homes-location-community-models-model",
    path: indextEPgfFEmMlMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: indextEPgfFEmMlMeta || {},
    alias: indextEPgfFEmMlMeta?.alias || [],
    redirect: indextEPgfFEmMlMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: index6IzbWscp0JMeta?.name ?? "homes-location-community-plans-plan",
    path: index6IzbWscp0JMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: index6IzbWscp0JMeta || {},
    alias: index6IzbWscp0JMeta?.alias || [],
    redirect: index6IzbWscp0JMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexs26NgHf5YwMeta?.name ?? "homes-location-community-qmi-home",
    path: indexs26NgHf5YwMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexs26NgHf5YwMeta || {},
    alias: indexs26NgHf5YwMeta?.alias || [],
    redirect: indexs26NgHf5YwMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexd9lF45WnNtMeta?.name ?? "index",
    path: indexd9lF45WnNtMeta?.path ?? "/",
    meta: indexd9lF45WnNtMeta || {},
    alias: indexd9lF45WnNtMeta?.alias || [],
    redirect: indexd9lF45WnNtMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationKrEU37Y7GEMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationKrEU37Y7GEMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationKrEU37Y7GEMeta || {},
    alias: knolls_45hoa_45informationKrEU37Y7GEMeta?.alias || [],
    redirect: knolls_45hoa_45informationKrEU37Y7GEMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionwRlGjGt2ajMeta?.name ?? "land-acquisition",
    path: land_45acquisitionwRlGjGt2ajMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionwRlGjGt2ajMeta || {},
    alias: land_45acquisitionwRlGjGt2ajMeta?.alias || [],
    redirect: land_45acquisitionwRlGjGt2ajMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45place96nb3CoxPGMeta?.name ?? "lifestyle-brents-place",
    path: brents_45place96nb3CoxPGMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45place96nb3CoxPGMeta || {},
    alias: brents_45place96nb3CoxPGMeta?.alias || [],
    redirect: brents_45place96nb3CoxPGMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenance3FGUsqOER1Meta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenance3FGUsqOER1Meta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenance3FGUsqOER1Meta || {},
    alias: experience_45lower_45maintenance3FGUsqOER1Meta?.alias || [],
    redirect: experience_45lower_45maintenance3FGUsqOER1Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93OG5lu7dsvzMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93OG5lu7dsvzMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93OG5lu7dsvzMeta || {},
    alias: _91testimonial_93OG5lu7dsvzMeta?.alias || [],
    redirect: _91testimonial_93OG5lu7dsvzMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexNk3YT0BlUzMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexNk3YT0BlUzMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexNk3YT0BlUzMeta || {},
    alias: indexNk3YT0BlUzMeta?.alias || [],
    redirect: indexNk3YT0BlUzMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJNUvFEiswMeta?.name ?? "lifestyle-reviews",
    path: indexnJNUvFEiswMeta?.path ?? "/lifestyle/reviews",
    meta: indexnJNUvFEiswMeta || {},
    alias: indexnJNUvFEiswMeta?.alias || [],
    redirect: indexnJNUvFEiswMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessElX879Kak8Meta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessElX879Kak8Meta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessElX879Kak8Meta || {},
    alias: whats_45lifefullnessElX879Kak8Meta?.alias || [],
    redirect: whats_45lifefullnessElX879Kak8Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_933mBabYyLvBMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_933mBabYyLvBMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_933mBabYyLvBMeta || {},
    alias: _91_46_46_46slug_933mBabYyLvBMeta?.alias || [],
    redirect: _91_46_46_46slug_933mBabYyLvBMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesYQNigqFNC5Meta?.name ?? "my-favorites",
    path: my_45favoritesYQNigqFNC5Meta?.path ?? "/my-favorites",
    meta: my_45favoritesYQNigqFNC5Meta || {},
    alias: my_45favoritesYQNigqFNC5Meta?.alias || [],
    redirect: my_45favoritesYQNigqFNC5Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchsSd1lFM9QKMeta?.name ?? "new-home-search",
    path: new_45home_45searchsSd1lFM9QKMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchsSd1lFM9QKMeta || {},
    alias: new_45home_45searchsSd1lFM9QKMeta?.alias || [],
    redirect: new_45home_45searchsSd1lFM9QKMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhousel4AdtBGBiqMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhousel4AdtBGBiqMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhousel4AdtBGBiqMeta || {},
    alias: easyhousel4AdtBGBiqMeta?.alias || [],
    redirect: easyhousel4AdtBGBiqMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionLuQ7GAmfhwMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionLuQ7GAmfhwMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionLuQ7GAmfhwMeta || {},
    alias: limitededitionLuQ7GAmfhwMeta?.alias || [],
    redirect: limitededitionLuQ7GAmfhwMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottageWvblOH3bk4Meta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottageWvblOH3bk4Meta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottageWvblOH3bk4Meta || {},
    alias: wee_45cottageWvblOH3bk4Meta?.alias || [],
    redirect: wee_45cottageWvblOH3bk4Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexs8R8xKt98WMeta?.name ?? "our-homes",
    path: indexs8R8xKt98WMeta?.path ?? "/our-homes",
    meta: indexs8R8xKt98WMeta || {},
    alias: indexs8R8xKt98WMeta?.alias || [],
    redirect: indexs8R8xKt98WMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsk3L6mlL8xqMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsk3L6mlL8xqMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsk3L6mlL8xqMeta || {},
    alias: our_45neighborhoodsk3L6mlL8xqMeta?.alias || [],
    redirect: our_45neighborhoodsk3L6mlL8xqMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usuhQHg5rzNAMeta?.name ?? "partner-with-us",
    path: partner_45with_45usuhQHg5rzNAMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usuhQHg5rzNAMeta || {},
    alias: partner_45with_45usuhQHg5rzNAMeta?.alias || [],
    redirect: partner_45with_45usuhQHg5rzNAMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsafNBtuDjAuMeta?.name ?? "privacyterms",
    path: privacytermsafNBtuDjAuMeta?.path ?? "/privacyterms",
    meta: privacytermsafNBtuDjAuMeta || {},
    alias: privacytermsafNBtuDjAuMeta?.alias || [],
    redirect: privacytermsafNBtuDjAuMeta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxcLZCGCXAi4Meta?.name ?? "sandbox",
    path: sandboxcLZCGCXAi4Meta?.path ?? "/sandbox",
    meta: sandboxcLZCGCXAi4Meta || {},
    alias: sandboxcLZCGCXAi4Meta?.alias || [],
    redirect: sandboxcLZCGCXAi4Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93a6UEbetD62Meta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93a6UEbetD62Meta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93a6UEbetD62Meta || {},
    alias: _91_46_46_46slug_93a6UEbetD62Meta?.alias || [],
    redirect: _91_46_46_46slug_93a6UEbetD62Meta?.redirect,
    component: () => import("/codebuild/output/src2133202071/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/build-with-us/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/career-opportunities/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/email-signup/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/get-updates/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/how-to-video-library/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/lifestyle/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/login/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/lost-password/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/maintenance-items/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/organism-page/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/register/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/reset-password/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/style-guide/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  },
  {
    name: component_45stub4mUuDhc13EMeta?.name ?? undefined,
    path: component_45stub4mUuDhc13EMeta?.path ?? "/warranty-service-request/",
    meta: component_45stub4mUuDhc13EMeta || {},
    alias: component_45stub4mUuDhc13EMeta?.alias || [],
    redirect: component_45stub4mUuDhc13EMeta?.redirect,
    component: component_45stub4mUuDhc13E
  }
]